// This file is generated via scripts/build-static-config.js
  // Do not modify directly as changes will be overwritten in CI

  export const CONFIGURATION = {
    country: 'nz',
    brand: 'bk',
    env: 'prod',
    graphqlEnv: 'prod',
    graphqlGatewayEnv: '',
    sanityEnv: 'prod',
    platform: 'web',
    commitRef: '1ccd686ab8451055cb672948026ea233a540ecb9',
    releaseTagRef: '7b34252',
    releaseTimestamp: '1743599067',
    sanityDataset: '',
    sanityProjectId: 'czqk28jt',
    appFlowBuildId: '',
    clickTrackingDomain: '',
    urlScheme: '',
    adyen: {
      clientKey: '0',
      environment: 'live',
      urlPrefix: '0',
    },
    apiKeys: {
      amplitude: '7b66f02bc7c6073835b8fbafdeef1e50',
      branch: 'key_live_lcZO4rZBI2g1WmldvtZzHnfetrg0FbZF',
      braze: '346b52ed-d7ce-4467-ab0b-df7d045f128b',
      brazeCluster: 'sdk.iad-03.braze.com',
      bringg: '',
      googleMaps: 'AIzaSyBSMnA_0Y8I5NSO5y7fE0dStwjli2D1U1w',
      tomtom: '',
      launchDarkly: '6446875ce330d213132d12ec',
      launchDarklyMobileKey: 'mob-cae970a5-3016-42de-ba35-e01a9caf78e7',
      mParticle: 'au1-5815327cc72b9645881fe14eb1f3809b',
      amplitudeCdpKey: '',
      amplitudeCdpRegion: '',
    },
    apple: {
      merchantId: '0',
      migrationMerchantId: '',
      paymentsNetworks: [],
      prepaidMerchantId: '0',
      migrationPrepaidMerchantId: '',
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    branch: {
      domains: ['burgerkingnzl.test-app.link','burgerkingnzl-alternate.test-app.link','burgerkingnzl.app.link','burgerkingnzl-alternate.app.link']
    },
    checkoutDotCom: {
      publicKey: '',
    },
    facebook: {
      socialLogin: {
        appId: '',
        redirectURI: '',
      },
    },
    aws: {
      gqlApiUrl: 'https://apse1-prod-bk.rbictg.com/graphql',
      gqlGatewayApiUrl: 'https://apse1-prod-bk-gateway.rbictg.com/graphql',
      region: 'ap-southeast-1',
      userPoolClientId: '4l1b4kv0olojg5r2vg84550q16',
      userPoolId: 'ap-southeast-1_axIVSp12T',
    },
    bringg: {
      region: '0',
    },
    datadog: {
      logs: {
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
      rum: {
        applicationId: 'd2ad1579-fd2d-4500-a5d1-baa39289ebe4',
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
    },
    firstData: {
      corporateStoreId: '',
    },
    forter: {
      siteId: '0',
    },
    google: {
      paymentsEnvironment: 'prod',
      paymentsGateway: 'adyen',
      marketPaymentsGatewayMerchantId: '',
      paymentsGatewayMerchantId: {
        ca: '0',
        us: '0',
        ch: '',
      },
      migrationPaymentsGateway: '',
      migrationPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      paymentsNetworks: [],
      prepaidPaymentsGateway: 'firstdata',
      prepaidPaymentsGatewayMerchantId: {
        ca: '0',
        us: '0',
        ch: '',
      },
      migrationPrepaidPaymentsGateway: 'firstdata',
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    googleAdManager: {
      campaignId: '',
    },
    googleTagManager: {
      containerId: 'GTM-PBXM755',
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: 'C0003',
        consentCategoryBraze: 'C0002',
        consentCategoryMParticle: 'C0001',
        globalConsentState: 'Opt-Out',
        scriptUrl: '0',
        scriptId: '0',
      },
      deleteAccountUrl: '',
    },
    pypestream: {
      scriptUrl: '',
      appId: '',
    },
    restaurants: {
      displayEnv: 'prod',
      validMobileOrderingEnvs: ['alpha','beta','live'],
    },
    urls: {
      braze: 'https://customer.iad-03.braze.com/api/v3',
      webBaseUrl: '0',
    },
    tokenEx: {
      publicKey: '0',
    },
    vrPayment: {
      braintreeTokenizationKey: '0',
    },
    orbital: {
      baseUrl: '',
      subscriberId: '',
      pieFormat: '',
      mode: '',
    },
    timeFormat: 'hh:mm aaaa',
    dateFormat: '',
    addressFormat: '',
    maxCartQuantity: '',
    appStoreDeeplink: '',
    iosDeepLink: '',
    androidDeepLink: '',
    cookieBanners: {
      cookieBanner:'customCookieBanner',
      scriptId: '',
      oneTrust: {
        cookieCompliance: {
          consentCategoryBranch: '',
          consentCategoryBraze: '',
          consentCategoryMParticle: '',
          consentCategoryDdRum: '',
          globalConsentState: '',
          scriptUrl: '',
          scriptId: '',
        },
      },
    }
  };